"use client";

import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Image } from "cloudinary-react";
import { LogoutButton } from "components/LogoutButton";
import { Modal } from "components/Modal";
import { Button } from "components/ui/button";
import Link from "next/link";
import { useState } from "react";
import type { Client } from "types/model/client";
import type { CurrentUser } from "types/model/user";
import { cn } from "utils/cn";

export const NavigationLinks = ({
  client,
  currentUser,
  isMobile = false
}: {
  client: Client | null;
  currentUser: CurrentUser | null;
  isMobile?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  if (isMobile) {
    return (
      <Modal
        isOpen={isOpen}
        onClose={isOpen => setIsOpen(isOpen)}
        showOverlay={false}
        className="w-[calc(100vw-1rem)] md:hidden"
        contentClassName="top-2 sm:top-2 sm:translate-y-[unset] bottom-[unset] max-w-[calc(100vw-1rem)] shadow-md border-gray-100"
        trigger={
          <Button
            variant="unstyled"
            className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </Button>
        }
      >
        <div className="w-full rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
          <div className="flex items-center justify-between px-5 pt-4">
            <div className="flex items-center">
              {client?.clientLogo?.path &&
                client.headerDisplay !== "nameOnly" && (
                  <Image
                    className="h-8 w-auto sm:h-10"
                    cloudName="pembee"
                    publicId={client.clientLogo?.filename}
                    height="40"
                    dpr="2"
                    alt="logo"
                  />
                )}
              {client?.headerDisplay !== "logoOnly" && (
                <span
                  className={cn(
                    "self-center text-center text-base font-bold text-indigo-600 sm:text-xl",
                    client?.clientLogo?.path &&
                      client.headerDisplay !== "nameOnly" &&
                      "ml-2"
                  )}
                  data-cy="client-name"
                >
                  {client?.name}
                </span>
              )}
            </div>
            <div className="-mr-2">
              <Button
                className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setIsOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </Button>
            </div>
          </div>
          <div className="px-2 pb-3 pt-2" role="none">
            {currentUser && (
              <Link
                href="/account"
                className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                role="menuitem"
                data-cy="link-my-account-mobile"
              >
                My account
              </Link>
            )}
            {currentUser && currentUser?.accessLevel >= 3 && (
              <Link
                href="/admin"
                className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                role="menuitem"
                data-cy="link-admin-mobile"
              >
                Admin
              </Link>
            )}
          </div>
          {currentUser ? (
            <LogoutButton isMobile close={() => setIsOpen(false)} />
          ) : (
            <Link
              href="/login"
              className="block w-full bg-gray-50 px-5 py-3 text-center font-medium text-indigo-600 hover:bg-gray-100"
            >
              Log in
            </Link>
          )}
        </div>
      </Modal>
    );
  }

  return (
    <>
      {currentUser ? (
        <>
          <Link
            href="/account"
            className="whitespace-no-wrap cursor-pointer text-sm font-medium leading-6 text-gray-500 hover:text-gray-900 focus:text-gray-900 focus:outline-none sm:text-base"
            data-cy="link-my-account"
          >
            My account
          </Link>
          {currentUser.accessLevel >= 3 && (
            <Link
              href="/admin"
              className="whitespace-no-wrap cursor-pointer text-sm font-medium leading-6 text-gray-500 hover:text-gray-900 focus:text-gray-900 focus:outline-none sm:text-base"
              data-cy="link-admin"
            >
              Admin
            </Link>
          )}
          <LogoutButton />
        </>
      ) : (
        <>
          <Link
            href="/login"
            className="whitespace-no-wrap text-sm font-medium leading-6 text-gray-500 hover:text-gray-900 focus:text-gray-900 focus:outline-none sm:text-base"
            data-cy="link-login"
          >
            Log in
          </Link>
          <span className="inline-flex rounded-md shadow-sm">
            <Link
              href="/register"
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              data-cy="link-register"
            >
              Register
            </Link>
          </span>
        </>
      )}
    </>
  );
};
