import type { AxiosError } from "axios";
import type { ErrorResponse } from "types";

export function handleRequestError(error: AxiosError<ErrorResponse>) {
  if (error.response?.status === 401) {
    window.location.href = "/login";
  }

  if (error.response?.status === 403) {
    window.location.href = "/unauthorised";
  }

  if (error.response?.status === 404) {
    window.location.href = "/404";
  }
}
