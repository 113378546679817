import { Dialog, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { formatDate } from "helpers/date";
import { formatCurrency } from "helpers/helpers";
import { Fragment, useRef } from "react";
import type { Client } from "types/model/client";
import type { PaymentSchedule } from "types/model/subscription-plan";
import { cn } from "utils/cn";

export const SubscriptionPaymentScheduleModal = ({
  paymentSchedule,
  client,
  isOpen,
  setIsOpen
}: {
  paymentSchedule: PaymentSchedule | null;
  client: Client;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const bodyRef = useRef(null);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={bodyRef}
        open={isOpen}
        onClose={setIsOpen}
        data-cy="modal-subscription-fixed-payment-schedule-details"
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setIsOpen(false)}
            />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block min-w-full transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:min-w-min sm:max-w-lg sm:align-middle">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div
                    className={cn(
                      "mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10"
                    )}
                  >
                    <CalendarIcon
                      className="h-6 w-6 text-indigo-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div
                    className="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left"
                    ref={bodyRef}
                  >
                    <Dialog.Title
                      as="h3"
                      className="mb-4 text-lg font-medium leading-6 text-gray-900"
                      data-cy="modal-subscription-fixed-payment-schedule-details-title"
                    >
                      Payment schedule
                    </Dialog.Title>
                    <div
                      className="mt-2"
                      data-cy="modal-subscription-fixed-payment-schedule-details-body"
                    >
                      <dl>
                        <div className="hidden sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                          <dd className="text-sm font-medium text-gray-500">
                            Date
                          </dd>
                          <dt className="text-sm font-medium text-gray-500">
                            Amount
                          </dt>
                        </div>
                        {paymentSchedule?.schedule?.map((item, i) => {
                          return (
                            <div
                              key={item._id}
                              className={cn(
                                "sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:py-5",
                                i > 0 && "mt-8"
                              )}
                            >
                              <dd className="text-sm font-medium text-gray-500">
                                {formatDate(
                                  item.date,
                                  client.dateFormat,
                                  client.timeZone
                                )}
                              </dd>
                              <dt className="text-sm text-gray-900">
                                <span className="sm:hidden">Amount: </span>
                                {formatCurrency({
                                  rawAmount: item.amount,
                                  currency: client.currency
                                })}
                              </dt>
                            </div>
                          );
                        })}
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setIsOpen(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
