import type { ClientDocument } from "models/Client";
import {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  parsePhoneNumber
} from "react-phone-number-input";
import type { Client } from "types/model/client";

export function formatPhoneNumberValue(
  value?: string,
  client?: Client | ClientDocument
): string {
  if (!value) return "";
  const phoneNumber = parsePhoneNumber(value);
  const formattedPhoneNumber =
    phoneNumber?.country === client?.country
      ? formatPhoneNumber(value)
      : formatPhoneNumberIntl(value);
  return formattedPhoneNumber;
}
