import type { FormTemplateRenderProps } from "@data-driven-forms/react-form-renderer";
import componentTypes from "@data-driven-forms/react-form-renderer/component-types";
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import { PhoneNumber } from "components/form/field//PhoneNumber";
import { ActivityAddOns } from "components/form/field/ActivityAddOns";
import { ActivityDateInstances } from "components/form/field/ActivityDateInstances";
import { ActivityGroups } from "components/form/field/ActivityGroups";
import { ActivityGroupsSelectOne } from "components/form/field/ActivityGroupsSelectOne";
import { ActivityTickets } from "components/form/field/ActivityTickets";
import { AdminBookingActivityTicketSelect } from "components/form/field/AdminBookingActivityTicketSelect";
import { AdminBookingItems } from "components/form/field/AdminBookingItems";
import { AdminBookingItemsAssignAttendee } from "components/form/field/AdminBookingItemsAssignAttendee";
import { AdminBookingItemsWizard } from "components/form/field/AdminBookingItemsWizard";
import { AgeInput } from "components/form/field/AgeInput";
import { Agreement } from "components/form/field/Agreement";
import { Attachments } from "components/form/field/Attachments";
import { AttendeeDetails } from "components/form/field/AttendeeDetails";
import { AttendeeSelect } from "components/form/field/AttendeeSelect";
import { AttendeeSubForm } from "components/form/field/AttendeeSubForm";
import { Autocomplete } from "components/form/field/Autocomplete";
import { CancellationPolicyRules } from "components/form/field/CancellationPolicyRules";
import { CcvProviders } from "components/form/field/CcvProviders";
import { Checkbox } from "components/form/field/Checkbox";
import { CheckboxMultiple } from "components/form/field/CheckboxMultiple";
import { CheckboxMultipleCircles } from "components/form/field/CheckboxMultipleCircles";
import { Currency } from "components/form/field/Currency";
import { DateAndTime } from "components/form/field/DateAndTime";
import { DateValidity } from "components/form/field/DateValidity";
import { DiscountRuleActivityGroups } from "components/form/field/DiscountRuleActivityGroups";
import { DiscountRuleDateRange } from "components/form/field/DiscountRuleDateRange";
import { DiscountRuleRedemptionLimits } from "components/form/field/DiscountRuleRedemptionLimits";
import { DiscountRuleSelect } from "components/form/field/DiscountRuleSelect";
import { DurationField } from "components/form/field/DurationField";
import { FieldArray } from "components/form/field/FieldArray";
import { FieldOptions } from "components/form/field/FieldOptions";
import { FileUpload } from "components/form/field/FileUpload";
import { HeaderShowHide } from "components/form/field/HeaderShowHide";
import { HomeTabsConfig } from "components/form/field/HomeTabsConfig";
import { ImageField } from "components/form/field/ImageField";
import { MultiActivityDiscountRuleConditions } from "components/form/field/MultiActivityDiscountRuleConditions";
import { MultiSessionDiscountRuleConditions } from "components/form/field/MultiSessionDiscountRuleConditions";
import { RadioGroupWithDescription } from "components/form/field/RadioGroupWithDescripton";
import { RadioGroupWithDescriptonPlain } from "components/form/field/RadioGroupWithDescriptonPlain";
import { RangeField } from "components/form/field/RangeField";
import { SectionHeader } from "components/form/field/SectionHeader";
import Select from "components/form/field/Select";
import { SessionOptions } from "components/form/field/SessionOptions";
import { SessionPassPurchaseOptions } from "components/form/field/SessionPassPurchaseOptions";
import { TextField } from "components/form/field/Text";
import { Toggle } from "components/form/field/Toggle";
import { UpgradePlan } from "components/form/field/UpgradePlan";
import { UserDetails } from "components/form/field/UserDetails";
import { UserDisplay } from "components/form/field/UserDisplay";
import { Venue } from "components/form/field/Venue";
import { Wizard } from "components/form/field/Wizard";
import { SubmitButton } from "components/form/SubmitButton";
import { AdminSelectWaitlistAttendee } from "features/attendees/components/AdminSelectWaitlistAttendee";
import { SelectWaitlistAttendee } from "features/attendees/components/SelectWaitlistAttendee";
import SelectFieldType from "features/fields/components/SelectFieldType";
import { StripeConnection } from "features/stripe/components/StripeConnection";
import { AdminSelectWaitlistSessions } from "features/waitlists/components/AdminSelectWaitlistSessions";
import { SelectWaitlistSessions } from "features/waitlists/components/SelectWaitlistSessions";
import dynamic from "next/dynamic";
import type { ReactNode } from "react";
import { SubmitVariant } from "types/form";

const Textarea = dynamic(() => import("components/form/field/Textarea"), {
  ssr: false
});
const DateInput = dynamic(() => import("components/form/field/Date"), {
  ssr: false
});
const Time = dynamic(() => import("components/form/field/Time"), {
  ssr: false
});

export enum ButtonsDisplay {
  AlignRight = "ALIGN_RIGHT",
  FullWidth = "FULL_WIDTH",
  Hidden = "HIDDEN",
  None = "NONE"
}

interface FormTemplateProps extends FormTemplateRenderProps {
  id?: string;
  submitLabel?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
  buttonsDisplay?: ButtonsDisplay;
  className?: string;
}

export const FormTemplate = ({
  id,
  formFields,
  submitLabel = "Save",
  children,
  buttonsDisplay = ButtonsDisplay.AlignRight,
  isLoading,
  className
}: FormTemplateProps) => {
  const { handleSubmit, onCancel, getState } = useFormApi();
  const { submitting } = getState();

  return (
    <form
      {...(id ? { id } : {})}
      onSubmit={event => {
        event.preventDefault();
        handleSubmit();
      }}
      className={className}
    >
      {formFields as ReactNode}
      {children}
      {buttonsDisplay === ButtonsDisplay.AlignRight && (
        <div className="mt-8 border-t border-gray-200 pt-5">
          <div className="flex justify-end">
            {onCancel && (
              <button
                type="button"
                onClick={onCancel}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Cancel
              </button>
            )}
            <span className="ml-3 inline-flex rounded-md shadow-sm">
              <SubmitButton
                isLoading={submitting || isLoading}
                disabled={submitting}
              >
                {submitLabel}
              </SubmitButton>
            </span>
          </div>
        </div>
      )}
      {buttonsDisplay === ButtonsDisplay.Hidden && (
        <button type="submit" id="form-submit-button" className="hidden">
          Submit
        </button>
      )}
    </form>
  );
};

// This was sadly necessary due to issues with react dropzone
export const UserModalFormTemplate = ({
  formFields,
  children
}: FormTemplateProps) => {
  const { handleSubmit } = useFormApi();
  return (
    <form
      id="userForm"
      onSubmit={event => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      {formFields as ReactNode}
      {children}
    </form>
  );
};

// This was sadly necessary due to issues with react dropzone
export const AttendeeModalFormTemplate = ({
  formFields,
  children
}: FormTemplateProps) => {
  const { handleSubmit } = useFormApi();
  return (
    <form
      id="attendeeForm"
      onSubmit={event => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      {formFields as ReactNode}
      {children}
    </form>
  );
};

// This was sadly necessary due to issues with react dropzone
export const NewUserFormTemplate = ({
  formFields,
  children
}: FormTemplateProps) => {
  const { handleSubmit, getState } = useFormApi();
  const { submitting } = getState();

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      {formFields as ReactNode}
      {children}
      <div className="mt-6">
        <SubmitButton
          disabled={submitting}
          isLoading={submitting}
          variant={SubmitVariant.FullWidth}
        >
          Register
        </SubmitButton>
      </div>
    </form>
  );
};

// This was sadly necessary due to issues with react dropzone
export const NewUserCheckoutFormTemplate = ({
  formFields,
  children
}: FormTemplateProps) => {
  const { handleSubmit, onCancel, getState } = useFormApi();
  const { submitting } = getState();

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      {formFields as ReactNode}
      {children}
      <div className="mt-8 border-t border-gray-200 pt-5">
        <div className="flex justify-end">
          {onCancel && (
            <button
              type="button"
              onClick={onCancel}
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Cancel
            </button>
          )}
          <span className="ml-3 inline-flex rounded-md shadow-sm">
            <SubmitButton isLoading={submitting} disabled={submitting}>
              Next
            </SubmitButton>
          </span>
        </div>
      </div>
    </form>
  );
};

export const SeamlessFormTemplate = ({
  id,
  formFields,
  submitLabel = "Save",
  children,
  buttonsDisplay = ButtonsDisplay.FullWidth,
  isLoading
}: FormTemplateProps) => {
  const { handleSubmit, getState } = useFormApi();
  const { submitting } = getState();

  return (
    <form
      {...(id ? { id } : {})}
      onSubmit={event => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      {formFields as ReactNode}
      {children}
      {buttonsDisplay === ButtonsDisplay.FullWidth && (
        <div className="mt-6">
          <SubmitButton
            disabled={submitting}
            isLoading={isLoading}
            variant={SubmitVariant.FullWidth}
          >
            {submitLabel}
          </SubmitButton>
        </div>
      )}
      {buttonsDisplay === ButtonsDisplay.Hidden && (
        <button type="submit" id="form-submit-button" className="hidden">
          Submit
        </button>
      )}
    </form>
  );
};

// data-driven-forms/react-form-renderer/component-types.js
export const componentMapper = {
  [componentTypes.TEXT_FIELD]: TextField,
  [componentTypes.TEXTAREA]: Textarea,
  [componentTypes.SELECT]: Select,
  [componentTypes.CHECKBOX]: Checkbox,
  [componentTypes.FIELD_ARRAY]: FieldArray,
  // [componentTypes.PLAIN_TEXT]: null,
  [componentTypes.DATE_PICKER]: DateInput,
  // [componentTypes.SUB_FORM]: null,
  // [componentTypes.RADIO]: null,
  // [componentTypes.TABS]: null,
  [componentTypes.TIME_PICKER]: Time,
  // [componentTypes.WIZARD]: null,
  [componentTypes.SWITCH]: Toggle,
  // [componentTypes.SLIDER]: null,
  // [componentTypes.DUAL_LIST_SELECT]: null,
  // [componentTypes.BUTTON]: null,
  // [componentTypes.INPUT_ADDON_GROUP]: null,
  // [componentTypes.INPUT_ADDON_BUTTON_GROUP]: null,
  "checkbox-multiple": CheckboxMultiple,
  "checkbox-multiple-circles": CheckboxMultipleCircles,
  "section-header": SectionHeader,
  "upgrade-plan": UpgradePlan,
  "activity-date-instances": ActivityDateInstances,
  "activity-tickets": ActivityTickets,
  "activity-addons": ActivityAddOns,
  "attendee-sub-form": AttendeeSubForm,
  "attendee-details": AttendeeDetails,
  "user-details": UserDetails,
  "attendee-select": AttendeeSelect,
  "radio-group-with-description": RadioGroupWithDescription,
  "radio-group-with-description-plain": RadioGroupWithDescriptonPlain,
  "stripe-connection": StripeConnection,
  "field-options": FieldOptions,
  "ccv-providers": CcvProviders,
  "multi-activity-discount-rule-conditions":
    MultiActivityDiscountRuleConditions,
  "multi-session-discount-rule-conditions": MultiSessionDiscountRuleConditions,
  "discount-rule-activity-groups": DiscountRuleActivityGroups,
  "activity-groups": ActivityGroups,
  "activity-groups-select-one": ActivityGroupsSelectOne,
  "discount-rule-select": DiscountRuleSelect,
  "session-pass-purchase-options": SessionPassPurchaseOptions,
  "date-validity": DateValidity,
  "user-display": UserDisplay,
  "home-tabs-config": HomeTabsConfig,
  "date-and-time": DateAndTime,
  "session-options": SessionOptions,
  "discount-rule-date-range": DiscountRuleDateRange,
  "discount-rule-redemption-limits": DiscountRuleRedemptionLimits,
  "cancellation-policy-rules": CancellationPolicyRules,
  "header-show-hide": HeaderShowHide,
  "age-input": AgeInput,
  agreement: Agreement,
  wizard: Wizard,
  duration: DurationField,
  range: RangeField,
  attachments: Attachments,
  image: ImageField,
  "file-upload": FileUpload,
  currency: Currency,
  autocomplete: Autocomplete,
  venue: Venue,
  "admin-booking-items": AdminBookingItems,
  "admin-booking-activity-ticket-select": AdminBookingActivityTicketSelect,
  "admin-booking-items-wizard": AdminBookingItemsWizard,
  "admin-booking-items-assign-attendee": AdminBookingItemsAssignAttendee,
  "select-waitlist-attendee": SelectWaitlistAttendee,
  "select-waitlist-sessions": SelectWaitlistSessions,
  "admin-select-waitlist-attendee": AdminSelectWaitlistAttendee,
  "admin-select-waitlist-sessions": AdminSelectWaitlistSessions,
  "select-field-type": SelectFieldType,
  phone: PhoneNumber
};

export type SchemaItem = FormTemplateProps["schema"]["fields"][0] & {
  component: keyof typeof componentMapper;
};
