import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import { isProtectedRoute } from "helpers/auth";
import { usePathname, useRouter } from "next/navigation";
import type { MutationResultPair } from "react-query";
import { queryCache, useMutation } from "react-query";
import type { ErrorResponse } from "types";
import { getErrorMessage } from "utils/getErrorMessage";

export default function useDeleteAuth(): MutationResultPair<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  null,
  unknown
> {
  const router = useRouter();
  const pathname = usePathname();

  return useMutation(
    async () => {
      try {
        return await axios.delete("/api/auth");
      } catch (error: unknown) {
        throw new Error(
          getErrorMessage({
            error,
            errorMessage: error => `Failed to log out: ${error.message}`,
            otherErrorMessage: "Failed to log out."
          })
        );
      }
    },
    {
      onSuccess: async () => {
        queryCache.invalidateQueries("currentUser");
        queryCache.invalidateQueries("basket");

        if (isProtectedRoute(pathname || "")) {
          router.push("/");
        }

        if (window["Beacon"] && window["Beacon"]("info")) {
          window["Beacon"]("destroy");
        }
      }
    }
  );
}
