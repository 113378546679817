import type { Field } from "@data-driven-forms/react-form-renderer";
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { SubmitButton } from "components/form/SubmitButton";
import { generateMultiSessionDiscountRuleConditionFormSchema } from "helpers/form/discounts";
import { wait } from "helpers/helpers";
import { isEmpty } from "lodash";
import { Fragment, useRef, useState } from "react";
import { FormTemplateType } from "types/form";
import type { Client } from "types/model/client";
import type {
  DiscountRuleUnit,
  MultiSessionCondition
} from "types/model/discount-rule";

export interface MultiSessionDiscountRuleItemFormData {
  minimumSessions: string;
  unit: DiscountRuleUnit;
  fixedAmount: string;
  percentage: number;
  amountPerSession: string;
}

const formPrefix = "multiSessionDiscountRuleConditionSubForm";

export const MultiSessionDiscountRuleConditionsAddEditModal = ({
  isOpen,
  editingItemId,
  client,
  onConfirmItemAddEdit,
  onCancel,
  setIsOpen
}: {
  isOpen: boolean;
  editingItemId: string | null;
  client: Client;
  onCancel: () => void;
  onConfirmItemAddEdit: (
    formData: MultiSessionDiscountRuleItemFormData,
    itemId: string | null
  ) => void;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const wrapperRef = useRef(null);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const { renderForm, getState, change } = useFormApi();

  const formValues = getState().values;
  const multiSessionConditionsFormValues: MultiSessionCondition[] =
    formValues.multiSessionConditions || [];

  const formSchema = generateMultiSessionDiscountRuleConditionFormSchema({
    formTemplate: FormTemplateType.Seamless,
    prefix: formPrefix,
    editingItem: multiSessionConditionsFormValues.find(
      item => item._id === editingItemId
    ),
    existingMinimumSessionValues: multiSessionConditionsFormValues
      .filter(item => item._id !== editingItemId)
      .map(item => item.minimumSessions),
    existingUnitValue: multiSessionConditionsFormValues.find(
      item => item._id !== editingItemId && item.unit
    )?.unit,
    client
  });

  async function handleSubmit() {
    setIsSubmitted(true);

    const formState = getState();
    const formData = formState.values[formPrefix];
    const formErrors = formState.errors?.[formPrefix];

    const hasErrors = !isEmpty(formErrors);

    if (hasErrors) return;

    setIsOpen(false);
    await wait(300); // Wait for modal to transition to not open before updating state stuff.
    onConfirmItemAddEdit(formData, editingItemId);
    change(formPrefix, undefined);
    setIsSubmitted(false);
  }

  async function handleClose() {
    setIsOpen(false);
    await wait(300); // Wait for modal to transition to not open before updating state stuff.
    onCancel();
    change(formPrefix, undefined);
    setIsSubmitted(false);
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={wrapperRef}
        open={isOpen}
        onClose={handleClose}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={handleClose}
            />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block min-w-full transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:min-w-min sm:max-w-lg sm:align-middle">
              <div
                className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4"
                ref={wrapperRef}
              >
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PlusIcon
                      className="h-6 w-6 text-indigo-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 w-full sm:ml-4 sm:mt-0">
                    <Dialog.Title
                      as="h3"
                      className="text-center text-lg font-medium leading-6 text-gray-900 sm:text-left"
                    >
                      {editingItemId ? "Edit condition" : "Add new condition"}
                    </Dialog.Title>
                    <div className="mb-4 mt-6">
                      {renderForm(
                        formSchema.fields.map(
                          field =>
                            ({
                              ...field,
                              isSubFormSubmitted: isSubmitted
                            }) as Field
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <SubmitButton
                  buttonType="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleSubmit}
                >
                  OK
                </SubmitButton>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
