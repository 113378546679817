import { DemoSiteBanner } from "components/DemoSiteBanner";
import { EmailVerificationMessage } from "components/EmailVerificationMessage";
import { CookieNotice } from "components/site/CookieNotice";
import { Footer } from "components/site/Footer";
import { Header } from "components/site/Header";
import { SiteDisabled } from "components/site/SiteDisabled";
import { UpdateRequiredAccountDetailsModal } from "components/site/UpdateRequiredAccountDetailsModal";
import { isDemoSite } from "consts/client";
import { ReadyWaitlistEntriesMessage } from "features/waitlists/components/ReadyWaitlistEntriesMessage";
import useClient from "hooks/useClient";
import useCurrentUser from "hooks/useCurrentUser";
import Head from "next/head";
import { cn } from "utils/cn";

export const Layout = ({
  pageName = "",
  shouldAddContainer = true,
  preventAccessIfClientDisabled = false,
  isIframe = false,
  shouldUseWhiteBg = false,
  children
}: {
  pageName?: string;
  shouldAddContainer?: boolean;
  preventAccessIfClientDisabled?: boolean;
  isIframe?: boolean;
  shouldUseWhiteBg?: boolean;
  children: React.ReactNode;
}) => {
  const clientQueryInfo = useClient();
  const { data: currentUser } = useCurrentUser();

  const clientName = clientQueryInfo.data?.name || "";

  const title = `${pageName}${pageName && clientName ? " | " : ""}${clientName}`;

  return (
    <>
      {isDemoSite && !isIframe && <DemoSiteBanner />}
      <Head>
        <title>{title}</title>
      </Head>
      {!isIframe && (
        <Header
          client={clientQueryInfo.data || null}
          currentUser={currentUser || null}
        />
      )}
      {clientQueryInfo.data?.enabled === false &&
      preventAccessIfClientDisabled ? (
        <SiteDisabled />
      ) : (
        <div
          className={cn(
            !shouldUseWhiteBg && "bg-gray-100",
            shouldAddContainer && !isIframe && "py-8 lg:py-12",
            !shouldAddContainer && !isIframe && "pb-8 lg:pb-12",
            isIframe && "min-h-screen py-6"
          )}
        >
          {shouldAddContainer && (
            <div
              className={cn("mx-auto max-w-7xl px-4", !isIframe && "sm:px-6")}
            >
              <section className="flex flex-col gap-4 px-2 sm:px-6 lg:px-0">
                <EmailVerificationMessage />
                <ReadyWaitlistEntriesMessage className="mb-6" />
              </section>
              {children}
            </div>
          )}
          {!shouldAddContainer && (
            <>
              <section className="flex flex-col">
                <div className="w-full bg-yellow-50">
                  <EmailVerificationMessage className="mx-auto w-full max-w-7xl px-4 sm:mt-0 sm:px-6" />
                </div>
                <div className="w-full bg-blue-100">
                  <ReadyWaitlistEntriesMessage className="mx-auto w-full max-w-7xl px-4 sm:mt-0 sm:px-6" />
                </div>
              </section>
              {children}
            </>
          )}
          {clientQueryInfo.data && (
            <CookieNotice client={clientQueryInfo.data} isIframe={isIframe} />
          )}
        </div>
      )}
      {!isIframe && clientQueryInfo?.data && (
        <Footer
          client={clientQueryInfo.data}
          shouldAddTopBorder={shouldUseWhiteBg}
        />
      )}
      {currentUser && clientQueryInfo.data && (
        <UpdateRequiredAccountDetailsModal
          currentUser={currentUser}
          client={clientQueryInfo.data}
          isOpen={Boolean(currentUser?.missingRequiredFieldIds?.length)}
        />
      )}
    </>
  );
};
