import { Dialog } from "@headlessui/react";
import { createMarkup, getHtmlFromRaw } from "helpers/string";
import { useRef } from "react";
import type { Agreement } from "types/model/booking-agreement";

export const BookingAgreementPolicyModal = ({
  bookingAgreement,
  isOpen,
  setIsOpen
}: {
  bookingAgreement: Agreement;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const contentEl = useRef(null);

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      initialFocus={contentEl}
      className="fixed inset-0 z-50 flex items-center justify-center px-2 pb-4 sm:px-4"
    >
      <div
        className="absolute inset-0 bg-gray-500 opacity-75"
        onClick={onClose}
      />
      <div
        className="relative flex w-full max-w-lg flex-col overflow-y-auto rounded-lg bg-white px-8 py-8 shadow-xl lg:max-w-3xl"
        style={{ maxHeight: "82%" }}
      >
        <div className="mb-5 md:border-b md:border-gray-200 md:pb-5">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {bookingAgreement.name}
          </h3>
        </div>
        <div
          className="prose prose-sm"
          ref={contentEl}
          dangerouslySetInnerHTML={createMarkup(
            getHtmlFromRaw(bookingAgreement.policyText)
          )}
        ></div>
      </div>
    </Dialog>
  );
};
