import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger
} from "components/ui/dialog";
import type { ComponentPropsWithoutRef, ElementRef, ReactNode } from "react";
import { forwardRef } from "react";
import type { Children } from "types";
import { cn } from "utils/cn";

/**
 * Reusable modal that can be used to display content in a dialog.
 */
export const Modal = ({
  isOpen,
  onClose,
  trigger,
  className,
  contentClassName,
  showOverlay = true,
  cy,
  children
}: Children & {
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  trigger?: ReactNode;
  className?: string;
  contentClassName?: string;
  showOverlay?: boolean;
  cy?: string;
  unstyled?: boolean;
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        onOpenAutoFocus={event => event.preventDefault()}
        showOverlay={showOverlay}
        className={contentClassName}
      >
        <div
          {...(cy && { "data-cy": cy })}
          className={cn(
            "max-h-[calc(100vh-2rem)] max-w-[calc(100vw-2rem)] overflow-y-auto",
            className
          )}
        >
          {children}
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface DivProps extends ComponentPropsWithoutRef<"div"> {
  className?: string;
  children: ReactNode;
}

export const ModalWrapper = forwardRef<ElementRef<"div">, DivProps>(
  ({ className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        "flex flex-col items-center gap-4 px-4 pb-4 pt-5 sm:flex-row sm:items-start sm:p-6 sm:pb-4",
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
);

ModalWrapper.displayName = "ModalWrapper";

export const ModalIconContainer = forwardRef<ElementRef<"div">, DivProps>(
  ({ className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        "mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10",
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
);

ModalIconContainer.displayName = "ModalIconContainer";

export const ModalContent = forwardRef<ElementRef<"div">, DivProps>(
  ({ className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={cn("flex w-full flex-col gap-4", className)}
      {...props}
    >
      {children}
    </div>
  )
);

ModalContent.displayName = "ModalContent";

export const ModalTitle = forwardRef<
  ElementRef<typeof DialogTitle>,
  ComponentPropsWithoutRef<typeof DialogTitle>
>(({ className, ...props }, ref) => (
  <DialogTitle
    ref={ref}
    className={cn(
      "text-center text-lg font-medium leading-6 text-gray-900 sm:text-left",
      className
    )}
    {...props}
  />
));

ModalTitle.displayName = "ModalTitle";
