export function formatWebAddress(url: string): string {
  try {
    // Use the URL constructor to parse the URL
    const parsedUrl = new URL(url);

    // Remove "www." and return the hostname
    return parsedUrl.hostname.replace(/^www\./, "");
  } catch (error) {
    // Return the original string if it's not a valid URL
    return url;
  }
}
