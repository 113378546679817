import { BookingAgreementPolicyModal } from "components/site/BookingAgreementPolicyModal";
import useAllAgreements from "hooks/useAllAgreements";
import { useState } from "react";
import type { Agreement } from "types/model/booking-agreement";

export const FooterAgreements = () => {
  const [bookingAgreementToShowInModal, setBookingAgreementToShowInModal] =
    useState<Agreement | null>(null);

  const { data: agreements = [], isLoading } = useAllAgreements(false);

  return (
    <>
      {isLoading ? (
        <TermsBlockSkeleton />
      ) : (
        getShouldRenderTermsBlock(agreements) && (
          <div className="mt-12 md:mt-0">
            <h3 className="text-sm font-semibold uppercase tracking-wider text-gray-400">
              Terms
            </h3>
            <ul className="mt-4 space-y-4">
              {getBookingAgreementsForFooter(agreements).map(
                bookingAgreement => (
                  <li key={bookingAgreement._id}>
                    <a
                      onClick={() =>
                        setBookingAgreementToShowInModal(bookingAgreement)
                      }
                      className="cursor-pointer text-base text-gray-400 hover:text-gray-500"
                    >
                      {bookingAgreement.name}
                    </a>
                  </li>
                )
              )}
            </ul>
          </div>
        )
      )}
      {bookingAgreementToShowInModal && (
        <BookingAgreementPolicyModal
          bookingAgreement={bookingAgreementToShowInModal}
          setIsOpen={() => setBookingAgreementToShowInModal(null)}
          isOpen={true}
        />
      )}
    </>
  );
};

function getShouldRenderTermsBlock(agreements: Agreement[]) {
  return (
    agreements?.filter(
      bookingAgreement =>
        bookingAgreement.linkToPolicy &&
        bookingAgreement.includePolicyInSiteFooter
    ).length > 0
  );
}

function getBookingAgreementsForFooter(agreements: Agreement[]) {
  return agreements.filter(
    bookingAgreement =>
      bookingAgreement.linkToPolicy &&
      bookingAgreement.includePolicyInSiteFooter
  );
}

const TermsBlockSkeleton = () => {
  return (
    <div className="mt-12 animate-pulse md:mt-0">
      <div className="h-4 w-16 rounded bg-gray-200"></div>
      <div className="mt-4 space-y-4">
        {[1, 2, 3].map(item => (
          <div key={item} className="h-5 w-32 rounded bg-gray-200"></div>
        ))}
      </div>
    </div>
  );
};
