"use client";

import useCurrentUser from "hooks/useCurrentUser";
import Link from "next/link";

export const SiteDisabled = () => {
  const currentUserQueryInfo = useCurrentUser();

  return (
    <div className="-mt-73 flex min-h-screen flex-col border-b border-gray-200 bg-white md:-mt-89">
      <div className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center">
        <div className="py-12">
          <div className="text-center">
            <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              Site disabled
            </h1>
            <p className="mt-2 text-base text-gray-500">
              Sorry, this site is currently disabled and bookings cannot be
              placed.
            </p>
            {currentUserQueryInfo.data &&
              currentUserQueryInfo.data?.accessLevel >= 10 && (
                <div className="mt-6">
                  <Link
                    href="/admin/settings/subscription"
                    className="text-base font-medium text-indigo-600 hover:text-indigo-500"
                    passHref
                  >
                    Reactivate subscription
                    <span aria-hidden="true"> &rarr;</span>
                  </Link>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
