import { SubmitButton } from "components/form/SubmitButton";
import { Loader } from "components/Loader";
import { ActivitiesListingNoResults } from "components/site/ActivitiesListingNoResults";
import { ActivitySessionListItem } from "components/site/ActivitySessionListItem";
import { fetchActivitiesAvailability } from "features/activities/utils/fetchActivitiesAvailability";
import { getActivityGroupIdsFromCurrentActivitiesResponse } from "helpers/activity";
import { formatDate } from "helpers/date";
import { getHasFiltersApplied } from "helpers/field";
import useActivitySessionsListing from "hooks/useActivitySessionsListing";
import useBasketActivities from "hooks/useBasketActivities";
import React, { useEffect, useState } from "react";
import type { QueryResult } from "react-query";
import { useQuery } from "react-query";
import type { SessionAvailabilityHash } from "types/model/activity";
import type { Client } from "types/model/client";
import type { FieldFilters } from "types/model/field-data";
import { cn } from "utils/cn";

export const HomeActivitySessionsListing = ({
  shouldUseGreyBg,
  filters,
  isIframe,
  listingPath,
  client
}: {
  shouldUseGreyBg: boolean;
  filters: FieldFilters;
  isIframe: boolean;
  listingPath: string;
  client: Client;
}) => {
  const activitySessionsListingQueryInfo = useActivitySessionsListing(
    JSON.stringify(filters),
    listingPath
  );
  const basketActivitiesQueryInfo = useBasketActivities();
  const [
    activityGroupIdsAvailabilityFetchedFor,
    setActivityGroupIdsAvailabilityFetchedFor
  ] = useState<string[]>([]);

  const { data: activitySessionAvailabilityData } = useQuery(
    "activitySessionAvailability"
  ) as QueryResult<SessionAvailabilityHash>;

  useEffect(() => {
    if (activitySessionsListingQueryInfo.data) {
      const activityGroupIds = getActivityGroupIdsFromCurrentActivitiesResponse(
        activitySessionsListingQueryInfo.data
      );
      const activityGroupIdsAvailabilityNotFetchedFor = activityGroupIds.filter(
        activityGroupId =>
          !activityGroupIdsAvailabilityFetchedFor.includes(activityGroupId)
      );
      if (activityGroupIdsAvailabilityNotFetchedFor.length) {
        fetchActivitiesAvailability(activityGroupIdsAvailabilityNotFetchedFor);
        setActivityGroupIdsAvailabilityFetchedFor([
          ...activityGroupIdsAvailabilityFetchedFor,
          ...activityGroupIdsAvailabilityNotFetchedFor
        ]);
      }
    }
  }, [
    activityGroupIdsAvailabilityFetchedFor,
    activitySessionsListingQueryInfo.data
  ]);

  return (
    <>
      {activitySessionsListingQueryInfo.isLoading ? (
        <Loader />
      ) : activitySessionsListingQueryInfo.data ? (
        <>
          {!activitySessionsListingQueryInfo.data[0].data.length && (
            <ActivitiesListingNoResults
              hasFiltersApplied={getHasFiltersApplied(filters)}
              shouldUseGreyBg={shouldUseGreyBg}
            />
          )}
          {activitySessionsListingQueryInfo.data.map((page, i) => (
            <React.Fragment key={i}>
              {page.data.map((dateItem, index) => (
                <div
                  key={index}
                  className={cn(
                    "mb-6 overflow-hidden rounded-lg bg-white",
                    !shouldUseGreyBg && "border border-gray-200",
                    shouldUseGreyBg && "shadow"
                  )}
                  data-cy="date-container"
                >
                  <div className="border-b border-gray-200 px-4 py-5 sm:px-6">
                    <h3 className="text-lg font-medium leading-6 text-gray-700">
                      {formatDate(
                        dateItem._id,
                        "EEEE do MMMM yyyy",
                        client.timeZone
                      )}
                    </h3>
                  </div>
                  <ul>
                    {dateItem.activities.map(activity => (
                      <ActivitySessionListItem
                        key={activity._id}
                        activity={activity}
                        overallAvailability={
                          activitySessionAvailabilityData?.[activity._id]
                        }
                        isAddedToBasket={
                          !!basketActivitiesQueryInfo.data?.activityIds.includes(
                            activity._id
                          )
                        }
                        isIframe={isIframe}
                      />
                    ))}
                  </ul>
                </div>
              ))}
            </React.Fragment>
          ))}
          {activitySessionsListingQueryInfo.canFetchMore && (
            <div className="flex justify-center">
              <SubmitButton
                isLoading={Boolean(
                  activitySessionsListingQueryInfo.isFetchingMore
                )}
                onClick={() => activitySessionsListingQueryInfo.fetchMore()}
                disabled={Boolean(
                  activitySessionsListingQueryInfo.isFetchingMore
                )}
                variant="fullWidth"
                type="white"
              >
                Load more
              </SubmitButton>
            </div>
          )}
        </>
      ) : null}
    </>
  );
};
