import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon
} from "@heroicons/react/20/solid";
import Badge from "components/Badge";
import { HelpText } from "components/form/HelpText";
import { kebabCase } from "lodash";
import PhoneInput from "react-phone-number-input";
import { FormTemplateType } from "types/form";
import { cn } from "utils/cn";

interface PhoneNumberProps extends UseFieldApiProps<string, HTMLElement> {
  isRequired?: boolean;
  isAdminOnly?: boolean;
  isSubFormSubmitted?: boolean;
}

export const PhoneNumber = (props: UseFieldApiConfig) => {
  const {
    label,
    input,
    isRequired,
    isAdminOnly,
    meta: { error, warning, touched },
    index,
    helpText,
    arrayField,
    inModal,
    formTemplate = FormTemplateType.Default,
    country,
    prefix,
    suffix,
    isSubFormSubmitted,
    hideHelpTextIfError = false,
    marginTop = 5,
    ...rest
  }: PhoneNumberProps = useFieldApi(props);

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  const touchedOrSubFormSubmitted = touched || isSubFormSubmitted;
  const marginTopClass = `mt-${marginTop}`;

  return (
    <div
      className={cn(
        !inModal &&
          isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !arrayField &&
          !isFirstItem &&
          `${marginTopClass} border-t border-gray-200 pt-5`,
        (isSeamlessFormTemplate || arrayField) && !isFirstItem && marginTopClass
      )}
      data-cy={`form-field-${kebabCase(label)}`}
    >
      <label
        className={cn(
          "text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",
          !isAdminOnly && "block",
          isAdminOnly && "flex flex-wrap gap-2"
        )}
        htmlFor={input.name}
      >
        {label}
        {isRequired && "*"}
        {isAdminOnly && <Badge>Admin only</Badge>}
      </label>
      <div
        className={cn(
          "mt-1",
          isDefaultFormTemplate && "sm:col-span-2 sm:max-w-sm"
        )}
      >
        <div
          className={cn("relative", prefix && "mt-1 flex rounded-md shadow-sm")}
        >
          {prefix && (
            <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
              {prefix}
            </span>
          )}

          <PhoneInput
            international={false}
            defaultCountry={country}
            placeholder="Enter phone number"
            className={cn(
              "flex rounded-md border outline outline-1 -outline-offset-1 outline-gray-300 focus-within:outline focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600",
              touchedOrSubFormSubmitted &&
                error &&
                "outline-red-300 focus-within:outline-red-600"
            )}
            data-cy={`input-${kebabCase(label)}`}
            {...input}
            {...rest}
          />

          {suffix && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="text-gray-500 sm:text-sm sm:leading-5">
                {suffix}
              </span>
            </div>
          )}
          {touchedOrSubFormSubmitted && error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
                data-cy={`icon-exclamation-${kebabCase(label)}`}
              />
            </div>
          )}
          {touchedOrSubFormSubmitted && warning && !error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
                data-cy={`icon-exclamation-warning-${kebabCase(label)}`}
              />
            </div>
          )}
        </div>
        {helpText &&
          !(touchedOrSubFormSubmitted && error && hideHelpTextIfError) && (
            <HelpText label={label} helpText={helpText} />
          )}
        {touchedOrSubFormSubmitted && error && (
          <p
            className="mt-2 text-sm text-red-600"
            data-cy={`error-${kebabCase(label)}`}
          >
            {error}
          </p>
        )}
        {touchedOrSubFormSubmitted && warning && (
          <p
            className="mt-2 text-sm text-yellow-700"
            data-cy={`warning-${kebabCase(label)}`}
          >
            {warning}
          </p>
        )}
      </div>
    </div>
  );
};
