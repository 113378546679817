import type { AxiosResponse } from "axios";
import axios from "axios";
import { queryCache } from "react-query";
import type {
  OverallAvailability,
  SessionAvailabilityHash
} from "types/model/activity";
import type { ActivityAvailabilityInfoData } from "types/model/aggregation";

export async function fetchActivitiesAvailability(activityGroupIds: string[]) {
  const res: AxiosResponse<ActivityAvailabilityInfoData[]> = await axios.get(
    `/api/activities/availability?activityGroupIds=${JSON.stringify(
      activityGroupIds
    )}`
  );

  const availabilityForChunk = res.data.reduce(
    (acc, item) => {
      acc[item._id.activityId] = item.activityAvailabilityStatus;

      return acc;
    },
    {} as { [key: string]: OverallAvailability }
  );

  queryCache.setQueryData(
    "activitySessionAvailability",
    (oldData: SessionAvailabilityHash | undefined) => {
      return {
        ...oldData,
        ...availabilityForChunk
      };
    }
  );
}
