import type { TipName } from "helpers/onboarding";
import type { Address, IdsHash } from "types/general";
import type { ClientLogo } from "types/model/client-logo";

export type DownloadAdditionalColumn = {
  name: string;
  width: number | string;
};

export type RegisterConfig = {
  attendeeFieldsVisibilityHash?: IdsHash;
  userFieldsVisibilityHash?: IdsHash;
  downloadFontSize?: number;
  showBookingNumber?: boolean;
  showBookingTotal?: boolean;
  showBookingBalance?: boolean;
  showTicketName?: boolean;
  showTicketAddOns?: boolean;
  showPaymentInfo?: boolean;
  showAttendeeAge?: boolean;
  displayAddOnsAsAbbreviations?: boolean;
  enableCheckIn?: boolean;
  enableCheckOut?: boolean;
  downloadAdditionalColumns?: DownloadAdditionalColumn[];
};

export type SessionsBookedReportConfig = {
  showTicketName?: boolean;
  showCheckedInStatus?: boolean;
  showCheckedOutStatus?: boolean;
  showCheckInTime?: boolean;
  showCheckOutTime?: boolean;
};

export type SessionsBookedReportExportConfig = {
  showUserId?: boolean;
  showAttendeeId?: boolean;
  showTicketName?: boolean;
  showCheckedInStatus?: boolean;
  showCheckedOutStatus?: boolean;
  showCheckInTime?: boolean;
  showCheckOutTime?: boolean;
};

export type ActivitySalesReportConfig = {
  showPlaceLimit?: boolean;
  showSalesCount?: boolean;
  showTicketSales?: boolean;
  showAddOnSales?: boolean;
  showTotalSales?: boolean;
};

export type AttendanceReportConfig = {
  showPlaceLimit?: boolean;
  showSalesCount?: boolean;
};

export type ActivitySalesByActivityGroupReportConfig = {
  showTotalSessions?: boolean;
  showPlaceLimit?: boolean;
  showAvePlacesSold?: boolean;
  showTicketSales?: boolean;
  showAddOnSales?: boolean;
  showTotalSales?: boolean;
};

export type ActivitiesCalendarConfig = {
  showWeekend?: boolean;
};

export type BookingExportConfig = {
  userFieldsVisibilityHash?: IdsHash;
  userInfoVisibilityHash?: IdsHash;
};

export type LineItemExportConfig = {
  activityFieldsVisibilityHash?: IdsHash;
  userFieldsVisibilityHash?: IdsHash;
  attendeeFieldsVisibilityHash?: IdsHash;
  bookingInfoVisibilityHash?: IdsHash;
  userInfoVisibilityHash?: IdsHash;
};

export type BookingsExportBookingInfoConfig = {
  showUserCreatedDate: boolean;
};

export type LineItemExportBookingInfoConfig = {
  showTicketName: boolean;
  showFirstSessionDate: boolean;
  showFinalSessionDate: boolean;
  showAllSessionDates: boolean;
  showNumberOfSessions: boolean;
  showUserCreatedDate: boolean;
};

export type ActivitySalesExportConfig = {
  activityFieldsVisibilityHash?: IdsHash;
};

export type AttendanceReportExportConfig = {
  activityFieldsVisibilityHash?: IdsHash;
};

export type SessionsBookedExportConfig = {
  activityFieldsVisibilityHash?: IdsHash;
  userFieldsVisibilityHash?: IdsHash;
  attendeeFieldsVisibilityHash?: IdsHash;
  idsVisibilityHash?: {
    userId?: boolean;
    attendeeId?: boolean;
  };
  otherFieldsVisibilityHash?: {
    ticketName?: boolean;
    checkedInStatus?: boolean;
    checkedOutStatus?: boolean;
    checkInTime?: boolean;
    checkOutTime?: boolean;
  };
};

export enum ListingType {
  Activities = "activities",
  Sessions = "sessions"
}

export enum Currency {
  // ones we can charge subscriptions in
  AUD = "aud",
  EUR = "eur",
  GBP = "gbp",
  NZD = "nzd",
  SGD = "sgd",
  USD = "usd",
  // others than can be selected
  CAD = "cad",
  DKK = "dkk",
  HKD = "hkd",
  NOK = "nok",
  SEK = "sek",
  AED = "aed",
  THB = "thb",
  QAR = "qar",
  MXN = "mxn",
  JPY = "jpy",
  SAR = "sar",
  FJD = "fjd",
  GHS = "ghs",
  MYR = "myr",
  CHF = "chf"
}

interface HomePageDisplay {
  listingType: ListingType;
}

interface HtgVoucherData {
  voucherCode: string;
  authorisationCode: string;
  productSku: string;
  productName: string;
  licenceTo: string;
  smeEmail: string;
  purchaserName: string;
  maxDiscountAllowed: number;
  currency: string;
  tokenExpiry: string;
}

export enum HtgStatus {
  InTrial = "inTrial",
  PendingReconciliation = "pendingReconciliation",
  Reconciled = "reconciled"
}

interface SubscriptionReminders {
  firstReminderSent: boolean;
  secondReminderSent: boolean;
}

interface CookieNotice {
  text: string;
  enabled: boolean;
}

interface MetaPixel {
  pixelId: string;
  enabled: boolean;
}

export interface StripeConnectedAccount {
  accountId: string;
  connected: boolean;
  toObject?: () => StripeConnectedAccount;
}

export enum AcquisitionSource {
  GooglePPC = "googlePPC",
  Organic = "organic",
  Email = "email",
  Capterra = "capterra"
}

export interface SetupTip {
  name: TipName;
  title: string;
  description: string;
  time: string;
  action: string;
  actionLink: string;
  dismissalsCount?: number;
}

export interface OnboardingInfo {
  isEmailVerified: boolean;
  hasCreatedActivity: boolean;
  hasCreatedVenue: boolean;
  setupTips: SetupTip[];
}

export interface SetupTipDismissal {
  name: TipName;
  dismissCount: number;
  lastDismissed: Date;
}

export type Waitlists = {
  expiryTimeForPlaceOffered?: number; // minutes
  setNotificationWindow?: boolean;
  notificationWindow?: {
    start: {
      hours: number;
      minutes: number;
    };
    end: {
      hours: number;
      minutes: number;
    };
  };
};

export interface Client {
  _id: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  name: string;
  subdomain: string;
  customDomain?: string;
  customDomainIsDefault?: boolean;
  contactEmail: string;
  currency: Currency;
  timeZone: string;
  dateFormat: string;
  dateFormatShort: string;
  stripeConnectedAccountId?: string;
  stripeConnectedAccounts?: StripeConnectedAccount[];
  stripeCustomerId?: string;
  stripeApplicationFee?: number;
  headerDisplay?: string;
  clientLogo?: ClientLogo;
  registerDownloadFontSize: number; // legacy
  registerConfig: RegisterConfig;
  sessionsBookedReport: SessionsBookedReportConfig;
  sessionsBookedReportExport: SessionsBookedReportExportConfig;
  activitySalesReport: ActivitySalesReportConfig;
  attendanceReport: AttendanceReportConfig;
  activitySalesByActivityGroupReport: ActivitySalesByActivityGroupReportConfig;
  activitiesCalendar: ActivitiesCalendarConfig;
  bookingsExportReport: BookingsExportBookingInfoConfig;
  lineItemsExportReport: LineItemExportBookingInfoConfig;
  homePageDisplay: HomePageDisplay;
  enableCancellationPolicy?: boolean;
  cancellationPolicyText?: string;
  phoneNumber?: string;
  webAddress?: string;
  taxIdentificationNumber?: string;
  facebookHandle?: string;
  instagramHandle?: string;
  twitterHandle?: string;
  address: Address;
  country: string;
  defaultPaymentEmailMsg: string;
  financialDocuments?: {
    headerMessage?: string;
  };
  trialLength: number;
  planLevel: number;
  allowEarlyAdopterPlan: boolean;
  subscriptionNotRequired: boolean;
  subscriptionPastDue: boolean;
  hasActiveSubscription: boolean;
  hasEverHadSubscription: boolean;
  subscriptionReminders: SubscriptionReminders;
  isHtgSignUp: boolean;
  htgVoucherData?: HtgVoucherData;
  htgStatus?: HtgStatus;
  bookingInvoicePrefix?: string;
  subscriptionDiscounts?: string[];
  collectBookingFeedback: boolean;
  signUpFollowUpEmailSent?: boolean;
  vatNotificationEmailSent?: boolean;
  cookieNotice: CookieNotice;
  metaPixel: MetaPixel;
  activityType: ClientActivityType;
  acquisitionSource: AcquisitionSource;
  gclid: string;
  firstPaymentFailureEmailSent: boolean;
  secondPaymentFailureEmailSent: boolean;
  thirdPaymentFailureEmailSent: boolean;
  fourthPaymentFailureEmailSent: boolean;
  allowAdminCommunicationInTrial: boolean;
  isPrimaryAdminUserEmailVerified?: boolean;
  enabled: boolean;
  created: Date;
  updated: Date;
  onboardingInfo?: OnboardingInfo;
  setupTipDismissals: SetupTipDismissal[];
  waitlists?: Waitlists;
  zapier?: {
    hashedKey?: string;
    salt?: string;
  };
  save: () => Promise<void>;
  deleteOne: () => Promise<void>;
  toObject: () => Client;
}

export enum ClientActivityType {
  AfterSchoolClub = "after_school_club",
  ArtCraftClasses = "art_craft_classes",
  CookingClasses = "cooking_classes",
  DanceAndPerformingArtsClasses = "dance_and_performing_arts_classes",
  FitnessYogaClasses = "fitness_yoga_classes",
  HolidayCamps = "holiday_camps",
  LanguageClasses = "language_classes",
  MusicSingingClasses = "music_singing_classes",
  PlayCenter = "play_center",
  ScienceTechnologyClasses = "science_technology_classes",
  SportsCoaching = "sports_coaching",
  TeamBuildingCorporateTraining = "team_building_corporate_training",
  Other = "other"
}

export interface ClientFeaturesUsed {
  addOns: boolean;
  discounts: boolean;
  childcarePayments: boolean;
  sessionPasses: boolean;
  accountCredit: boolean;
  abandonedCartEmail: boolean;
  customDomain: boolean;
  tracking: boolean;
  adminUsers: number;
  waitlists: boolean;
}

export type IClient = Omit<Client, "_id" | "save" | "deleteOne" | "toObject">;
