"use client";

import { Image } from "cloudinary-react";
import { CartButton } from "components/CartButton";
import { NavigationLinks } from "components/NavigationLinks";
import Link from "next/link";
import type { Client } from "types/model/client";
import type { CurrentUser } from "types/model/user";
import { cn } from "utils/cn";

export function Header({
  client,
  currentUser
}: {
  client: Client | null;
  currentUser: CurrentUser | null;
}) {
  return (
    <div className="sticky top-0 z-10">
      <div className="border-b border-gray-200 bg-white">
        <div className="mx-auto max-w-7xl px-4 py-5 sm:px-6 sm:py-6">
          <nav
            className="relative flex items-center justify-between sm:h-10 md:justify-center"
            aria-label="Global"
          >
            <div className="flex w-full items-center justify-between md:w-auto">
              <Link
                href="/"
                data-cy="link-home"
                passHref
                className="flex flex-1 cursor-pointer"
              >
                {client?.clientLogo?.path &&
                  client.headerDisplay !== "nameOnly" && (
                    <Image
                      className="h-8 w-auto sm:h-10"
                      cloudName="pembee"
                      publicId={client.clientLogo?.filename}
                      height="40"
                      dpr="2"
                      alt="Logo"
                    />
                  )}
                {client?.headerDisplay !== "logoOnly" && (
                  <span
                    className={cn(
                      "self-center text-center text-base font-bold text-indigo-600 sm:text-xl",
                      client?.clientLogo?.path &&
                        client.headerDisplay !== "nameOnly" &&
                        "ml-2"
                    )}
                    data-cy="client-name"
                  >
                    {client?.name}
                  </span>
                )}
              </Link>
              <div className="-my-2 mr-1 md:hidden">
                <CartButton isMobile />
              </div>
              <div className="-mr-2 flex items-center md:hidden">
                <NavigationLinks
                  client={client}
                  currentUser={currentUser}
                  isMobile
                />
              </div>
            </div>
            <div
              id="md-cart-user-links"
              className="hidden items-center justify-end space-x-4 sm:space-x-8 md:flex md:flex-1 lg:w-0"
            >
              <CartButton />
              <NavigationLinks client={client} currentUser={currentUser} />
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
