"use client";

import { Button } from "components/ui/button";
import { createMarkup, getHtmlFromRaw } from "helpers/string";
import Script from "next/script";
import { useEffect, useState } from "react";
import type { Client } from "types/model/client";

type CookieChoice = "accepted" | "rejected";

export const CookieNotice = ({
  client,
  isIframe
}: {
  client: Client;
  isIframe: boolean;
}) => {
  const [cookieChoice, setCookieChoice] = useState<
    CookieChoice | null | undefined
  >(null);

  useEffect(() => {
    const storedChoice =
      (localStorage.getItem("cookieChoice") as CookieChoice) || undefined;

    setCookieChoice(storedChoice);
  }, []);

  function handleCookieChoice(choice: CookieChoice) {
    setCookieChoice(choice);

    localStorage.setItem("cookieChoice", choice);
  }

  /** If the cookie preference isn't chosen by the user, it will be undefined */
  const showCookieNotice =
    client.cookieNotice?.enabled && !isIframe && cookieChoice === undefined;

  const showMetaPixel =
    client.metaPixel.enabled &&
    client.metaPixel.pixelId &&
    (!client.cookieNotice.enabled || cookieChoice === "accepted");

  return (
    <>
      {showCookieNotice && (
        <div className="fixed inset-x-0 bottom-0 flex flex-col justify-between gap-x-8 gap-y-4 bg-white p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-8">
          <div
            className="prose prose-sm max-w-4xl"
            dangerouslySetInnerHTML={createMarkup(
              getHtmlFromRaw(client.cookieNotice?.text)
            )}
          />
          <div className="flex flex-none items-center gap-x-5">
            <Button
              type="button"
              onClick={() => handleCookieChoice("rejected")}
              variant="link"
            >
              Reject all
            </Button>
            <Button
              type="button"
              onClick={() => handleCookieChoice("accepted")}
            >
              Accept all
            </Button>
          </div>
        </div>
      )}
      {showMetaPixel && (
        <Script
          id="fb-pixel"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', ${client.metaPixel.pixelId});
            `
          }}
        />
      )}
    </>
  );
};
