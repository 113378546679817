import type { AxiosResponse } from "axios";
import axios from "axios";
import { queryCache } from "react-query";
import type {
  OverallAvailability,
  SessionAvailabilityHash
} from "types/model/activity";
import type { ActivityGroupAvailabilityInfoData } from "types/model/aggregation";

export async function fetchActivityGroupsAvailability(
  activityGroupIds: string[]
) {
  const activityIdsChunks = activityGroupIds.reduce(
    (resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 5);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    },
    [] as string[][]
  );

  for (const activityIdsChunk of activityIdsChunks) {
    const res: AxiosResponse<ActivityGroupAvailabilityInfoData[]> =
      await axios.get(
        `/api/activity-groups/availability?activityGroupIds=${JSON.stringify(
          activityIdsChunk
        )}`
      );

    const availabilityForChunk = res.data.reduce(
      (acc, item) => {
        acc[item._id] = item.activityGroupStatus;
        return acc;
      },
      {} as { [key: string]: OverallAvailability }
    );

    queryCache.setQueryData(
      "activityAvailability",
      (oldData: SessionAvailabilityHash | undefined) => {
        return {
          ...oldData,
          ...availabilityForChunk
        };
      }
    );
  }
}
